@font-face {
  font-family: 'InterLight';
  src: url('../public/fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'InterBold';
  src: url('../public/fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none!important;
  margin: 0!important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield!important;
}

html,
body {
  /* turn off the overscroll/bounce effect when the browser reaches the boundary of a scrolling area */
  /* overscroll-behavior-y works for non-Safari browsers */
  overscroll-behavior-y: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-family: "Inter" !important;
  color: rgb(30, 30, 30);
  font-size: 20px;
}

.inter {
  font-family: Inter !important;
}

.inter-bold {
  font-family: InterBold !important;
}

.inter-light {
  font-family: InterLight !important;
  font-size: 16px;
}

.list {
  list-style: inside;
}

.treepublic-logo-image {
  max-width: 100%;
  box-sizing: border-box;
  max-height: 72px;
  margin-bottom: 24px;
}

.corner-round {
  border-radius: 20px
}

.text-white {
  color: white !important;
}

.image-proposal {
  max-width: 100%;
}

.image-small {
  height: 40px;
}

.styled-input {
  box-sizing: border-box;
  max-width: 100vw;
  width: 100%;
  margin: 0px;
  border: none;
  font-size: 20px;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 32px !important;
  background: #ebebeb;
}

select.styled-input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.grey {
  color: grey;
}

.border-white {
  border: 1px white solid;
}

.input-transparent {
  background: none;
  border: none;
  border-bottom: 1px solid black;
  width: 60%;
}

.ubuntu {
  font-family: 'Ubuntu';
}

.white {
  color: white;
}

.black {
  color: black;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold !important;
}

.fw-light {
  font-weight: 200 !important;
}

.block {
  display: block;
}

.text-small {
  font-size: 18px !important;
}

.text-medium {
  font-size: 22px !important;
}

.text-large-responsive {
  font-size: 35px;
  white-space: wrap;
  /* overflow: hidden; */
  text-overflow: wrap;
  overflow-wrap: break-word;
}

.super {
  font-size: 0.6em;
  vertical-align: top;
  margin-left: 6px;
}

.text-start {
  text-align: start;
}

.text-start::placeholder {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.w-30 {
  width: 30%;
  max-width: 30%;
}

.w-30-m {
  max-width: 30%;
}

.w-40 {
  width: 40%;
  max-width: 40%;
}

.w-70 {
  width: 70%;
  max-width: 70%;
}

.w-100 {
  width: 100%;
  max-width: 100%;
}

.fit-screen {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh; */
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-8 {
  flex-grow: 8;
}

.flex {
  display: flex;
}

.flex img {
  align-self: center;
}

.flex div,
.flex button {
  flex: 1;
  align-self: stretch;
  min-width: 0;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

.flex-vertical {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
}

.flex-vertical-end {
  display: flex;
  align-items: end;
  justify-content: end;
  flex-direction: column;
  box-sizing: border-box;
}

.flex-stretch {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  justify-content: space-between;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.flex-buttons-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.flex-buttons-mobile button {
  min-height: 100px;
  width: 20%;
  /* width: calc(50% - 2em); */
  margin: 1em;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-start {
  align-items: start;
}

.justify-start {
  justify-content: start;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.span-2 {
  grid-column: span 2;
}

.span-3 {
  grid-column: span 3;
}

.span-4 {
  grid-column: span 4;
}

.span-6 {
  grid-column: span 6;
}

.span-8 {
  grid-column: span 8;
}

.span-9 {
  grid-column: span 9;
}

.span-12 {
  grid-column: span 12;
}

.row-span-2 {
  grid-row: span 2;
}

.row-span-3 {
  grid-row: span 3;
}

.row-span-4 {
  grid-row: span 4;
}

.grid-3-col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(5, 1fr); */
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.grid-2-col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}

.grid-col-span-2 {
  grid-column: span 2;
}

.h-4 {
  height: 48px;
}

.h-12 {
  height: 144px;
}

.h-90 {
  height: 90%;
}

.h-10 {
  height: 10%;
}

.h-20vh {
  height: 20vh;
}

.h-30vh {
  height: 30vh;
}

.h-40vh {
  height: 40vh;
}

.h-50-px {
  height: 50px;
}

.h-min-100vh {
  min-height: 100vh;
  box-sizing: border-box;
}

.h-min-100vh-minus-nav {
  min-height: 100vh;
  box-sizing: border-box;
  height: calc(100vh - 40px);
}

.icon {
  max-width: 48px;
  display: inline-block;
}

.proposal-container {
  font-family: Inter !important;
  width: 100%;
  min-height: 100vh;
  margin: 0px;
  padding: 24px;
  padding-top: 96px;
  background: #E2E2E2 !important;
  box-sizing: border-box;
}

.proposal {
  width: 100%;
}

.proposal-card-image {
  border: #1E1E1E solid 2px;
  background-color: "white";
  background-size: cover!important;
  background-position: 'center' !important;
  background-repeat: 'no-repeat';
  width: '100%';
}

.background-clean-energy-to-life {
  min-height: 200px;
  background-image: url('./images/roof/clean_energy_to_life.png')!important;
  background-repeat: no-repeat!important;
  background-position: top left!important;
}

.grid-card-image {
  border: #1E1E1E solid 2px;
}

.proposal-grid {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  grid-auto-rows: auto;
}

.proposal-title {
  text-align: center;
  color: #8B7B43;
  font-size: 30px !important;
  font-weight: bold;
  font-family: InterBold;
}

.proposal-callout {
  background: #BEB07F;
  border-radius: 20px;
  padding: 24px;
}

.proposal-callout p {
  color: white;
}

.proposal-card {
  background: #B4CC76;
  color: #1E1E1E;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.proposal-card p {
  /* color: white; */
  margin-bottom: 5px;
}

.proposal-card-secondary {
  background: white;
  color: black !important;
}

.vertical-container-minus-stepper-and-logo {
  box-sizing: border-box;
  height: calc(100vh - 60px - 72);
}

.vertical-container-minus-stepper {
  box-sizing: border-box;
  height: calc(100vh - 60px);
}

.h-100 {
  height: 100%;
}

.m-0-svg-container svg {
  margin: 0px !important;
}

.m-auto {
  display: block;
  margin: 0px auto;
}

.m-1 {
  margin: 12px;
}

.mt-1 {
  margin-top: 12px;
}

.mt-2 {
  margin-top: 24px;
}

.mt-3 {
  margin-top: 36px;
}

.mt-4 {
  margin-top: 48px;
}

.mt-8 {
  margin-top: 96px;
}

.ml-tiny {
  margin-left: 4px;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 12px;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 12px;
}

.ml-neg-4 {
  margin-left: -48px;
}

.mb-tiny {
  margin-bottom: 4px;
}

.mb-1 {
  margin-bottom: 12px;
}

.mb-0 {
  margin-bottom: 0px !important;
}


.mb-2 {
  margin-bottom: 24px;
}

.mb-3 {
  margin-bottom: 36px;
}

.mb-4 {
  margin-bottom: 48px;
}

.mb-8 {
  margin-bottom: 96px;
}

.m-1-0 {
  margin: 12px 0px !important;
}

.pr-4-5 {
  padding-right: 54px;
}

.p-2 {
  padding: 24px;
}

.p-0-2 {
  padding: 0px 24px;
}

.p-4 {
  padding: 48px;
}

.p-0-4 {
  padding: 0px 48px;
}

.p-0-8 {
  padding: 0px 96px;
}

.p-4-0 {
  padding: 48px 0px;
}

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 12px !important;
}

.p-2 {
  padding: 24px !important;
}

.p-5 {
  padding: 60px !important;
}

.p-3-2 {
  padding: 36px 24px !important;
}

.pb-8 {
  padding-bottom: 96px !important;
}

.datepicker-override-styles svg {
  fill: white;
  color: white;
}

.container-padding {
  padding: 48px 0px;
}

.f-1-5 {
  font-size: 1.5rem;
}

.f-2 {
  font-size: 2rem;
}

.f-3 {
  font-size: 3rem;
}

.ls-0 {
  text-indent: 0rem;
  letter-spacing: 0rem;
}

.ls-04 {
  text-indent: 0.4rem;
  letter-spacing: 0.4rem;
}

.ls-08 {
  text-indent: 0.8rem;
  letter-spacing: 0.8rem;
}

.ls-1 {
  text-indent: 1rem;
  letter-spacing: 1rem;
}

.icon-small {
  width: 24px;
}

.relative {
  position: relative;
}

.navbar {
  height: 40px;
  background: rgb(241, 241, 241);
  box-shadow: 0 1px 3px 1px rgba(63, 66, 64, 0.16);
}

.upper-right {
  position: absolute;
  top: 10px;
  right: 10px;
}

.container-70vh {
  color: black;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding-top: 24px;
  min-height: 70vh;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

}

.container-rounded-top {
  background: white;
  color: black;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding-top: 24px;
  min-height: 70vh;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.responsive-width-100-50 {
  width: 100%;
  max-width: 100vw;
}

.responsive-width-90-70 {
  width: 90%;
  max-width: 90vw;
  margin: 0 auto;
}

.responsive-height-800-300 {
  height: 300px;
}

@media (max-width:900px) {
  .tablet-hidden {
    display: none;
  }
}

@media (max-width:600px) {
  .flex-buttons-mobile {
    flex-direction: column!important;
  }

  .flex-buttons-mobile button {
    width: 100%;
    margin: 0;
    margin-bottom: 1em;
  }

  .text-large-responsive {
    font-size: 28px;
  }

  .mobile-hidden {
    display: none;
  }

  .mobile-span-4 {
    grid-column: span 4 !important;
  }

  .mobile-span-8 {
    grid-column: span 8 !important;
  }

  .mobile-span-12 {
    grid-column: span 12 !important;
  }

  .mobile-mt-4 {
    margin-top: 8rem !important;
  }

  .stepper .button-default {
    min-width: 60px;
  }
}

@media (min-width:1100px) {
  .text-large-responsive {
    font-size: 38px !important;
  }
}

@media (min-width:600px) {
  .text-large-responsive {
    font-size: 25px;
  }

  .responsive-height-800-300 {
    height: 500px;
  }

  /* hi-res laptops and desktops */
  .fixed-width-desktop {
    max-width: 50vw;
    margin: 0 auto;
  }

  .responsive-width-90-70 {
    max-width: 70vw;
    margin: 0 auto;
  }

  .proposal {
    max-width: 70vw;
    margin: 0 auto;
  }


  /* .responsive-width-100-50, .navbar {
    width: 50vw;
  } */
}

.stepper {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.stepper-grey {
  margin-right: 20px !important;
  margin-left: 20px !important;
  text-align: center;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  color: #000 !important;
  background-color: #ebebeb !important;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.stepper-completed {
  text-align: center;
  color: #000 !important;
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  border-radius: 20px;
  padding: 0.01em 16px;
  width: 25%;
  min-width: 10%;
  height: 100%;
  background: rgb(209, 224, 220);
}

.stepper-percentage {
  text-align: center;
  font-family: Arial, sans-serif !important;
  font-size: 20px !important;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  color: white;
  margin: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.underline {
  text-decoration: underline !important;
}

.button-container-vertical {
  text-align: center;
}

.button-container-vertical button {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.button-default {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 20px;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  min-width: 120px;
  font-size: 18px;
}

.button-default:hover {
  text-decoration: none;
}

.button-default:disabled {
  pointer-events: none;
  cursor: default;
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}

.button-primary {
  background: #93b2a6;
  color: white;
}

.button-primary:hover {
  text-decoration: none;
  background-color: #a3c9bb;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.button-secondary {
  background: white;
  color: #93b2a6;
}

.button-secondary:hover {
  /* background-color: rgba(147, 178, 166, 0.04); */
  color: #6e9184;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}

.button-secondary:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.button-text {
  font-family: Arial, sans-serif;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background: none;
}

.button-text img {
  max-height: 100%;
}

.button-text:hover {
  cursor: pointer;
}


.reset,
.reset p,
.reset button {
  color: black;
  letter-spacing: 0rem;
}

.pointer:hover {
  cursor: pointer;
}


/* reset */

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

#googleMap {
  border-radius: 20px;
}

body {
  line-height: 1;
  background: #f7f7f7;
  /* background: #faf8eb; */
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}